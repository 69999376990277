<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-card
        v-if="blog !== null"
        class="blog-edit-wrapper"
    >
      <!--    Editor Modal  -->
      <b-modal
          id="modal-Editor"
          centered
          title="افزودن کد html"
          ok-title="ثبت"
          ok-only
          @ok.preventDefault()="customButtonClick"
      >
        <b-row>
          <span class="mx-1 mb-1">کد HTML خود را در اینجا قرار دهید</span>
          <b-col cols="12">
            <textarea class="form-control" v-model="rawHTML"></textarea>
          </b-col>

        </b-row>
      </b-modal>

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col md="6">
            <b-form-group
                label="تیتر"
                label-for="blog-edit-title"
                class="mb-2"
            >
              <b-form-input
                  id="blog-edit-title"
                  v-model="blog.title"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="سر تیتر"
                label-for="blog-edit-subTitle"
                class="mb-2"
            >
              <b-form-input
                  id="blog-edit-subTitle"
                  v-model="blog.subTitle"
              />
            </b-form-group>
          </b-col>

          <!-- Field:  publishDate -->
          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                label="تاریخ و زمان انتشار"
                label-for=""
            >
              <input
                  type="text"
                  :id="`start${1}`"
                  placeholder="تاریخ و زمان انتشار"
                  :value="createJalaliDate(blog.publishDate)"
                  class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
              />
              <custom-date-picker
                  v-model="blog.publishDate"
                  auto-submit
                  input-format="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  display-format="jYYYY/jMM/jDD HH:mm"
                  class=""
                  :element="`start${1}`"
                  type="datetime"
                  color="#5c6bc0"
                  :min="new Date(Date.now())"
              ></custom-date-picker>
            </b-form-group>
          </b-col>

          <!-- Field:  status -->
          <b-col cols="6">
            <b-form-group
                label="وضعیت"
                label-for="status"
                class="mb-2 mt-1"
            >
              <v-select
                  id="status"
                  v-model="blog.status"
                  :options="statusOptions"
                  dir="rtl"
                  :reduce="val => val.value"
              />
            </b-form-group>
          </b-col>

          <!-- Field:  summary -->
          <b-col
              cols="12"
          >
            <b-form-group
                label="خلاصه"
                label-for="summary"
            >
              <b-form-textarea
                  v-model="blog.summary"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="توضیحات"
                label-for="blog-content"
                class="mb-0"
            >
              <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent" :defaultContent="blog.description"></ckEditorMain>
            </b-form-group>
            <textarea v-if="showHTML" dir="ltr" id="" v-model="rawHTML" class="form-control mb-2 " cols="30" name="textArea"
                      rows="10"></textarea>
          </b-col>
          <b-col
              cols="12"
              class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                پیوست عکس
              </h4>
              <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                      ref="refPreviewEl"
                      :src="base64ImageSrc"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <div class="d-inline-block">
                    <b-form-file
                        ref="refInputEl"
                        accept=".jpg, .png, .gif"
                        placeholder="انتخاب عکس"
                        @input="makeBase64Pic"
                    />
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card>

    <b-card v-if="blog !== null" class="blog-edit-wrapper">
      <h2>سئو</h2>

      <b-form class="mt-2">
        <b-row>
          <b-col md="6" class="">
            <b-form-group
                label="عنوان"
                label-for="blog-edit-title"
                class=""
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="blog.seoTitle"
              />
            </b-form-group>
            <span class=""
                  :class="{'text-danger':blog.seoTitle.length>60}">تعداد کاراکترهای مجاز <small>{{
                blog.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="10">
                <b-form-group
                    label="آدرس"
                    label-for="blog-edit-seourl"
                    class=""
                >
                  <b-form-input
                      id="blog-edit-seourl"
                      v-model="allCharactersToDash"
                      v-on:focus="$event.target.select()"
                      ref="myInput"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                    variant="warning"
                    class="mt-2 ml-2"
                    @click="copyText"
                >
                  کپی
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col cols="12" class="mt-2">
            <b-form-group
                label="توضیحات"
                label-for="blog-content"
                class="mb-2"
            >
              <b-form-textarea
                  v-model="blog.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span class=""
                  :class="{'text-danger':blog.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{
                blog.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>

          <b-card class="mt-1 w-100">

            <h2>نحوه نمایش در گوگل</h2>

            <div class="col-md-12 my-3">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ blog.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span style="overflow-wrap: anywhere">{{ blog.seourl.replaceAll(' ', '-') }}/</span>
                    <span>
                             {{ 'https://enzamode.ir/magazine/'+blogId+'/' }}
                        <span class="serp-arrow"></span>
                      </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ blog.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
                <div class="serp-title">
                  <h6>
                    {{ blog.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ blog.seourl.replaceAll(' ', '-') }}/</small>
                  <small>
                    {{ 'https://enzamode.ir/magazine' }}
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ blog.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-card>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="updateBlog"
            >
              ذخیره تغییرات
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="blog !== null">
      <h2 class="mb-2">دسته بندی ها</h2>

      <b-badge
          v-for="(category,idx) in blog.blogCategories"
          :key="idx"
          variant="success"
          class="p-2 cursor-pointer m-1"
      >
        <feather-icon
            @click="deleteCategory(category)"
            icon="XIcon"
            class="text-danger"
            style="width: 2rem;height: 2rem"
        />
        <span class="ml-1" style="font-size: 25px">
          {{ category.name }}
        </span>
      </b-badge>

      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
              label="افزودن دسته بندی"
              label-for="category"
              class="mb-2"
          >
            <v-select
                v-if="blogCategories !== null"
                id="category"
                v-model="selectedCategory"
                :options="blogCategories"
                label="name"
                :reduce="name => name.blogCategoryId"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-button
              variant="success"
              class="col-12 col-md-2 mt-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="addCategory"
          >
            افزودن
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="blog !== null">
      <h2 class="mb-2">برچسب ها</h2>

      <b-badge
          v-for="(tag,idx) in blog.tags"
          :key="idx"
          variant="warning"
          class="p-2 cursor-pointer m-1"
      >
        <feather-icon
            @click="deleteTag(tag)"
            icon="XIcon"
            class="text-danger"
            style="width: 2rem;height: 2rem"
        />
        <span class="ml-1" style="font-size: 25px">
          {{ tag.value }}
        </span>
      </b-badge>

      <b-row class="mt-2">
        <b-col md="6" class="">
          <b-form-group
              class="mb-2"
              label="برچسب جدید"
              label-for="tagIds"
          >
            <b-form-input
                id="tagIds"
                v-model="newTagValue"
                placeholder="برچسب جدید"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-button
              variant="warning"
              class="col-12 col-md-2 mt-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="addTag"
          >
            افزودن
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- Image Library Modal  -->
    <library-image-picker @insertImage="insertImage"></library-image-picker>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BBadge,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import LibraryImagePicker from "@/views/pages/blog/LibraryImagePicker";
import {
  BlogBlogCategoryCreateRequest,
  BlogGetByIdRequest,
  BlogTagCreateRequest,
  BlogBlogCategoryDeleteRequest,
  TagGetAllRequest, BlogTagDeleteRequest, TagCreateRequest, TagDeleteRequest
} from "@/libs/Api/Blog";
import {BlogCategoryGetAllRequest} from "@/libs/Api/Blog";
import {BlogUpdateBlogRequest} from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import ckEditorMain from "@/views/components/ckEditorMain";
export default {
  title: "ویرایش بلاگ",
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BOverlay,
    vSelect,
    BModal,
    ckEditorMain,
    BBadge,
    LibraryImagePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      baseURL: Helper.baseUrl,
      blog: null,
      blogId: this.$route.params.id,
      showOverlay: false,
      blogCategories: null,
      base64ImageSrc: null,
      blogEdit: {},
      tags: null,
      selectedTag: null,
      selectedCategory: null,
      blogFile: null,
      newTagValue: null,
      showHTML:false,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: this.imageMethod,
            },
          },
        },
      },
      rawHTML: '',
      statusOptions: [
        {label: 'منتشر شده', value: 1},
        {label: 'حذف شده', value: 2},
        {label: 'پیش نویس', value: 3},
      ],
    }
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.blog.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.blog.seourl = newValue
          // Remove all characters that are NOT number
          this.blog.seourl = this.blog.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.blog.seourl = this.blog.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.blog.seourl === "") {
          this.blog.seourl = null;
        }
      },
    },
  },
  async created() {
    await this.getBlog();
    await this.getBlogCategories();
    await this.getBlogTags(0, 0);
  },
  methods: {
    setEditorContent(content){
      this.blog.description = content
    },
    imageMethod() {
      this.$bvModal.show("library-image-picker-modal");
    },
    insertImage(imageUrl) {
      this.$refs.quillEditor.quill.insertEmbed(
          this.$refs.quillEditor.quill.getSelection()?.index
              ? this.$refs.quillEditor.quill.getSelection().index
              : 1,
          "image",
          imageUrl
      );
    },
    async getBlog() {
      let _this = this;
      _this.showOverlay = true;

      let blogGetByIdRequest = new BlogGetByIdRequest(_this);
      blogGetByIdRequest.setId(_this.blogId)
      await blogGetByIdRequest.fetch(function (content) {
        _this.blog = content;
        _this.base64ImageSrc = _this.baseURL + _this.blog.fileData;
        _this.blog.fileData = '';
        _this.showOverlay = false;
        // _this.blog.tagIds= _this.blog.tags;
      }, function (error) {
        _this.showOverlay = true;
        console.log(error)
      });
    },
    async getBlogTags(pageNumber, count) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        pageNumber: pageNumber,
        count: count
      }

      let tagGetAllRequest = new TagGetAllRequest(_this);
      tagGetAllRequest.setParams(data);
      await tagGetAllRequest.fetch(function (content) {
        _this.tags = content.tags;
        _this.showOverlay = false;
        // _this.totalCount = content.tagsCount;
      }, function (content) {
        _this.showOverlay = false;

      });
    },
    async getBlogCategories() {
      let _this = this;
      _this.showOverlay = true;

      let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
      await blogCategoryGetAllRequest.fetch(function (content) {
        _this.blogCategories = content.blogCategories;
        _this.showOverlay = false;
      }, function (content) {
        _this.showOverlay = false;

      });
    },
    async updateBlog() {
      this.$refs.editorContent.emitDataToParent()
      let _this = this;
      _this.showOverlay = true;

      let blogUpdateBlogRequest = new BlogUpdateBlogRequest(_this);
      blogUpdateBlogRequest.setParams(_this.blog);
      await blogUpdateBlogRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات انجام شد.`,
          },
        })
        _this.showOverlay = false;
        window.location.reload()
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      _this.blog.fileData = await _this.readAsDataURL(file);
    },
    async deleteCategory(category) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        id: category.blogBlogCategoryId,
      }

      let blogBlogCategoryDeleteRequest = new BlogBlogCategoryDeleteRequest(_this);
      blogBlogCategoryDeleteRequest.setParams(data);
      await blogBlogCategoryDeleteRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی حذف شد.`,
          },
        })
        _this.showOverlay = false;
        window.location.reload();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async addCategory() {
      let _this = this;
      _this.showOverlay = true;
      let data = {
        isDeleted: false,
        blogBlogCategoryId: 0,
        blogId: _this.blog.blogId,
        blogCategoryId: _this.selectedCategory
      }

      let blogBlogCategoryCreateRequest = new BlogBlogCategoryCreateRequest(_this);
      blogBlogCategoryCreateRequest.setParams(data);
      await blogBlogCategoryCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی اضافه شد.`,
          },
        })
        _this.showOverlay = false;
        window.location.reload();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async addTag() {
      let _this = this;
      _this.showOverlay = true;
      let data = {
        isDeleted: false,
        tagId: 0,
        value: _this.newTagValue,
        objectId: _this.blog.blogId,
        type: 4,
        createDate: new Date(Date.now())
      }

      let tagCreateRequest = new TagCreateRequest(_this);
      tagCreateRequest.setParams(data);
      await tagCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برچسب اضافه شد.`,
          },
        })
        _this.showOverlay = false;
        window.location.reload();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async deleteTag(tag) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        id: tag.tagId,
      }

      let tagDeleteRequest = new TagDeleteRequest(_this);
      tagDeleteRequest.setParams(data);
      await tagDeleteRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برچسب حذف شد.`,
          },
        })
        _this.showOverlay = false;
        window.location.reload();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    createJalaliDate(param) {
      if (param !== '') {

        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
    customButtonClick() {
      this.showHTML = !this.showHTML

      const editor = document.querySelector('.ql-editor')
      if (editor.classList.contains('d-none')) {
        this.blog.description = this.rawHTML
        editor.classList.remove('d-none')

      } else {
        this.rawHTML = this.blog.description
        editor.classList.add('d-none')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
